<template>
  <b-card-code title="جدول كتب المنتسب" no-body>
    <b-card-body v-if="role">
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="2"
          label-align-sm="center"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن.."
            /><b-input-group-append>
              <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col md="3">
          <b-form-group>
            <v-select
              v-model="search.type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.types"
              placeholder="اختر نوع الكتاب"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <v-select
              v-model="search.subtype_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.subtypes"
              placeholder="اختر النوع الفرعي"
            />
          </b-form-group>
        </b-col>
        <b-col md="3"
          >
            <b-form-group
            >
              <cleave
                id="date"
                v-model="search.fromdate"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="من YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <b-col md="3"
          >
            <b-form-group
            >
              <cleave
                id="date"
                v-model="search.todate"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="الى YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
      </b-form-row>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(users)="data">
        <b-badge
          v-for="stage in data.item.users"
          :key="stage.id"
          pill
          :variant="`success`"
        >
          {{ stage.full_name }}
        </b-badge>
      </template>
      <template #cell(Document)="file">
        <b-button v-if="file.item.file!==null"
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          @click="onClicked(file.item.file)"
        >
          show
        </b-button>
        <b-button v-else
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-danger"
          disabled
        >
          didn't uploaded 
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="Accept"
      centered
      size="lg"
      title="Edit Document"
      @ok="uM"
      @hidden="rEF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">Select type of document</label>
              <v-select
                v-model="editform.users"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="full_name"
                multiple
                :options="users"
                placeholder="Select employee"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="basic-password">Select type of document</label>
              <v-select
                v-model="editform.type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="types"
                placeholder="Select Type"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="basic-password">Select sub type of document</label>
              <v-select
                v-model="editform.subtype_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="subtypes"
                placeholder="Select Sub Type"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">Title :</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.title"
              :state="editform.title.length > 0"
              placeholder="title"
            />
          </b-col>

          <b-col md="6">
            <label for="input-invalid1">Number:</label>
            <b-form-input
              id="input-invalid1"
              v-model="editform.number"
              placeholder="document's number"
            />
          </b-col>
          <b-col md="6"
          >
            <b-form-group
              label="Date"
              label-for="date"
            >
              <cleave
                id="date"
                v-model="editform.date"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">Description:</label>
            <b-form-textarea
              id="input-invalid1"
              placeholder="document's number"
              v-model="editform.description"
                rows="3"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">choose Document File:</label>
            <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-model="editform.file"
              @change="selectFileForForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="Accept"
      centered
      size="lg"
      title="Add Document"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">Select type of document</label>
              <v-select
                v-model="form.users"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="full_name"
                multiple
                :options="users"
                placeholder="Select employee"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="basic-password">Select type of document</label>
              <v-select
                v-model="form.type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="types"
                placeholder="Select type"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="basic-password">Select sub type of document</label>
              <v-select
                v-model="form.subtype_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="subtypes"
                placeholder="Select Sub type"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">Title :</label>
            <b-form-input
              id="input-valid1"
              v-model="form.title"
              :state="form.title.length > 0"
              placeholder="title"
            />
          </b-col>

          <b-col md="6">
            <label for="input-invalid1">Number:</label>
            <b-form-input
              id="input-invalid1"
              v-model="form.number"
              placeholder="document's number"
            />
          </b-col>
          <b-col md="6"
      >
        <b-form-group
          label="Date"
          label-for="date"
        >
          <cleave
            id="date"
            v-model="form.date"
            class="form-control"
            :raw="false"
            :options="date"
            placeholder="YYYY-MM-DD"
          />
        </b-form-group>
      </b-col>
          <b-col md="12">
            <label for="input-invalid1">Description:</label>
            <b-form-textarea
              id="input-invalid1"
              placeholder="document's number"
              v-model="form.description"
                rows="3"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">choose Document File:</label>
            <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-model="form.file"
              @change="selectFileForForm"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="Delete User"
      @ok="dM"
      ok-title="Accept"
    >
      <b-card-text>
        do you really want to delete this item " {{ this.editform.ar_name }} " ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import { filesUrl } from "@/main.js";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,BProgress,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BRow,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,BFormTextarea,
  BFormFile,
  BCardBody,
  BIconFileEarmarkSlides,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { SID,TID,AID } from "@/main.js";
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import emailjs from '@emailjs/browser';
export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,
    BAvatar,BProgress,
  BFormFile,Cleave,
    BBadge,
    BRow,
    BFormRow,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormTextarea,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      role: null,
      canEdit:null,
      canDelete: null,
      currentPage: 0,
      department: "",
      title: "",
      iD:"",
      item: "",
      id: "",
      filter: null,
      filterOn: [],
      departments: [],
      teachers: [],
      users: [],
      search:{
        types: [],
        subtypes: [],
        type_id: "",
        subtype_id: "",
        fromdate:"",
        todate:""
      },
      fields: [
        {
          key: "number",
          label: "رقم الكتاب",
        },
        {
          key: "title",
          label: "الموضوع",
        },
        {
          key: "date",
          label: "التاريخ",
        },
        {
          key: "type.ar_title",
          label: "نوع الكتاب",
        },
        {
          key: "subtype.ar_title",
          label: "النوع الفرعي",
        },
        {
          key: "Document",
          label: "الكتاب",
        },
        { key: "users", label: "محول الى" },
      ],
      exportFields:{
          'Title': 'ar_name',
            'Stage': 'stage.ar_name',
            'Syllabus': 'celbas',
            'Department': 'department.ar_name',
            'Quntity': 'files_count',
            'Lecturer': 'teacher.ar_name',
      },
      exportData:[
        {
          TotalName: "full_name",
          Stage: "stage.ar_name",
          Syllabus: "celbas",
          Department: "department.ar_name",
          Quntity: "files_count",
          Lecturer: "teacher.ar_name",
        },
      ],
      items: [],
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        user_id:"",
        teacher_id:"",
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      form: {
        title: "",
        number: "",
        date: "",
        type_id: "",
        subtype_id: "",
        description: "",
        file: "",        
        users: [],
      },
      editform: {
        title: "",
        number: "",
        date: "",
        type_id: "",
        subtype_id: "",
        description: "",
        file: "",
        users: [],
        id: null,
      },
      date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
          },
      toEmail:false,
      toName:false,
      subject:null,
      toID:null,
      m:null,
      toUserID:null,
      types:[],
      subtypes:[]
    };
  },
  watch: {
    title: function (val) {
      this.gS();
    },
    "$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
    "$route.params.document_id": {
      handler: function (search) {
        this.gS();
      },
    },
    "search.type_id": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
    "search.fromdate": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
    "search.todate": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
    "search.subtype_id": function (val) {
      if (val != null || val != "") {
        this.gS()
      }
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    if (localStorage.getItem("NisourRole") == "admin"||localStorage.getItem("NisourRole") == "hr") {
      this.role = true;
    } else {
      this.role = false;
    }
    if (localStorage.getItem("NisourRole") == "admin"||localStorage.getItem("NisourRole") == "coordinator") {
      this.canEdit = true;
      if (localStorage.getItem("NisourRole") == "coordinator") {
      this.iD = localStorage.getItem("NisourDepartmentId");
    } 
    } else {
      this.canEdit = false;
    }
    
    if (localStorage.getItem("NisourRole") == "admin") {
      this.canDelete = true;
    } else {
      this.canDelete = false;
      this.search.department = localStorage.getItem("NisourDepartmentId");
    }
    this.ID(), this.gS(), this.gU(),this.gTD(),this.gSTD();
  },
  methods: {
    async gTD(){
await this.axios
        .get(
          `types?hr=true`
        )
        .then((res) => {
          this.types = [];
          this.types = res.data.items;
          this.search.types = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSTD(){
await this.axios
        .get(
          `subtypes`
        )
        .then((res) => {
          console.log(`this is the result ${res}`)
          this.subtypes = [];
          this.subtypes = res.data.items;
          this.search.subtypes = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    
    async gU(){
await this.axios
        .get(
          `users-name`
        )
        .then((res) => {
          this.users = [];
          this.users = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async uM() {
      
      if (isNaN(this.editform.type_id)) {
        this.editform.type_id = this.editform.type_id["id"];
      }
      if (isNaN(this.editform.subtype_id)) {
        this.editform.subtype_id = this.editform.subtype_id["id"];
      }
      const ids = []
      if (this.editform.users.length>0) {
        this.editform.users.map(function(value, key) {
          ids.push(value.id);
        });
      }
      let data = new FormData();
      data.append("title", this.editform.title);
      data.append("number", this.editform.number);
      data.append("date", this.editform.date);
      data.append("type_id", this.editform.type_id);
      data.append("subtype_id", this.editform.subtype_id);
      data.append("description", this.editform.description);
      data.append("file", this.editform.file);
      ids.forEach((element) =>
        data.append("users[]", element))
      await this.axios
        .post(`documents/${this.editform.id}`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {

        if (this.editform.users.length>0){
            console.log(this.editform)
            for (let i = 0; i < this.editform.users.length; i++) {
            this.toEmail =this.editform.users[i].email,
            this.toName =this.editform.users[i].full_name,
            this.toID =this.editform.users[i].teacher_id,
            this.toUserID =this.editform.users[i].id,
            this.subject =this.editform.title,
            this.m = `سيد ${this.toName} : نود اعلامك بورود كتاب رسمي من ${localStorage.getItem("NisourUserName")} يرجى فتح الحساب و تأكيد الاستلام `
            this.sendEmail(this.m,this.toName,this.toEmail);
            this.notifi.route = "documents-notif";
            this.notifi.title = "كتاب رسمي وارد ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "documents";
            this.notifi.user_id = this.toUserID;
            this.notifi.type = "light-success";
            this.notifi.subtitle = ` ورد اليك كتاب رسمي `;
            this.notifi.bg = "bg-success";
            this.sendNoti();
          }}
      
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          this.precentage=0
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    sendEmail(m,n,e) {
      console.log(e)
      const fromName = localStorage.getItem("NisourUserName")
      const fromEmail = localStorage.getItem("NisourEmail")
      emailjs.send(SID,TID,{
          from_name: fromName,
         to_name: n, 
          message: m,
          to_email: e,
          reply_to:fromEmail
        },AID)
        .then((result) => {
            this.makeToast(
              "success",
              "Notification Sender",
              `The notification has been sent successfully`
            );
            this.toName = ""
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    },
    async aNM() {
      if (isNaN(this.form.type_id)) {
        this.form.type_id = this.form.type_id["id"];
      }
      if (isNaN(this.form.subtype_id)) {
        this.form.subtype_id = this.form.subtype_id["id"];
      }
      const ids = []
      if (this.form.users.length>0) {
        this.form.users.map(function(value, key) {
          ids.push(value.id);
        });
      }
      console.log(ids)
       let data = new FormData();
      data.append("title", this.form.title);
      data.append("number", this.form.number);
      data.append("type_id", this.form.type_id);
      data.append("date", this.form.date);
      data.append("subtype_id", this.form.subtype_id);
      data.append("description", this.form.description);
      data.append("file", this.form.file);
      ids.forEach((element) =>
        data.append("users[]", element))
      await this.axios
        .post(`documents`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          console.log(res.data)
          if (res.data.errors) {
            this.errorToast();
          } else {
            if (this.form.users.length>0){
            for (let i = 0; i < this.form.users.length; i++) {
            this.toEmail =this.form.users[i].email,
            this.toName =this.form.users[i].full_name,
            this.toID =this.form.users[i].teacher_id,
            this.toUserID =this.form.users[i].id,
            this.subject =this.form.title,
            this.m = `سيد ${this.toName} : نود اعلامك بورود كتاب رسمي من ${this.fromName} يرجى فتح الحساب و تأكيد الاستلام `
            this.sendEmail(this.m,this.toName,this.toEmail);
            this.notifi.route = "documents-notif";
            this.notifi.title = "كتاب رسمي وارد ";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "documents";
            this.notifi.user_id = this.toUserID;
            this.notifi.type = "light-success";
            this.notifi.subtitle = `ورد اليك كتاب رسمي من قبل ${this.fromName}`;
            this.notifi.bg = "bg-success";
            this.sendNoti();
          }}
            this.gS();
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    rF() {
      this.form.title= "",
        this.form.numbe= "",
        this.form.date= "",
        this.form.type_id= "",
        this.form.subtype_id= "",
        this.form.description= "",
        this.form.file= ""
    },
    rEF() {
      this.editform.title= "",
        this.editform.numbe= "",
        this.editform.date= "",
        this.editform.type_id= "",
        this.editform.subtype_id= "",
        this.editform.description= "",
        this.editform.file= ""
    },
    async dM() {
      await this.axios
        .delete(`lecture/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
            this.notifi.route = "lectures-notif";
            this.notifi.title = "delete action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "lectures";
            this.notifi.role_id = 1;
            this.notifi.type = "light-danger";
            this.notifi.subtitle = "deleted lecture";
            this.notifi.bg = "bg-danger";
            this.sendNoti();
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async rDM(id) {
      await this.axios
        .get(`lecture/recycle/${id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      console.log(item);
      this.editform.title= item.title,
      this.editform.number= item.number,
      this.editform.date = item.date;
      this.editform.type_id = item.type_id;
      this.editform.subtype_id = item.subtype_id;
      this.editform.description = item.description;
      this.editform.file = item.file;
      this.editform.users = item.users;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.name = item.name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    ID() {
      this.iD = localStorage.getItem("NisourUserId");
    },
    async gS() {
      if (isNaN(this.search.type_id) & (this.search.type_id != "")) {
        this.search.type_id = this.search.type_id["id"];
      }
      if (isNaN(this.search.subtype_id) & (this.search.subtype_id != "")) {
        this.search.subtype_id = this.search.subtype_id["id"];
      }
      await this.axios
        .get(
          `employee-documents?take=${this.perPage}&skip=${
            this.currentPage - 1
          }
          &type_id=${this.search.type_id}&fromdate=${this.search.fromdate}&todate=${this.search.todate}
          &subtype_id=${this.search.subtype_id}&title=${this.title}&user_id=${this.$route.params.id}
          `
        )
        .then((res) => {
            console.log(res.data.items)
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
          
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    selectFileForForm(e) {
      this.form.file = "";
      this.form.file = e.target.files[0];
    },
    selectFileForEditForm(e) {
      this.editform.file = "";
      this.editform.file = e.target.files[0];
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      (this.title = ""), (this.search.type_id = ""),(this.search.subtype_id = ""),(this.search.fromdate = ""),(this.search.todate = "");
      this.gS();
    },
    onClicked(path) {
      window.open(`${filesUrl}/${path}`, "_blank");
    },
  },
};
</script>
